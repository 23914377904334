<template>
  <TTView>
    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Требования к логину:

          <ul>
            <li>Длинна от 2 до 1024 символов</li>
            <li>Должен начинаться со строчных латинских букв</li>
            <li>Может содержать цифры 0-9</li>
            <li>Может содержать специальные символы .+-</li>
          </ul>
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Требования к паролю:

          <ul>
            <li>Минимум 8 символов</li>
            <li>Минимум буквы и цифры</li>
            <li>Минимум буквы в разных кейсах</li>
          </ul>
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <UserForm
          :entity="user"
          @update:login="user.login = $event"
          @update:password="user.password = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_USER } from '../../components/authentication/common';
import UserForm from '../../components/authentication/UserForm.vue';

export default {
  name: 'UsersCreate',

  components: {
    UserForm,
  },

  inject: ['Names'],

  data() {
    return {
      loading: false,
      user: { ...DEFAULT_USER },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        const { user } = this;
        const { id: userId } = await this.$di.api.Authentication.NewUser(user);
        this.$router.push({
          name: this.Names.R_AUTHENTICATION_USER,
          params: { userId },
        });
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.snackError('Логин должен содержать от 2 до 1024 символов, '
          + 'должен начинаться со строчных латинских букв, может содержать цифры 0-9 и специальные символы .+-'
          + ' Пароль - обязательное поле');
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
