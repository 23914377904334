/* eslint-disable import/prefer-default-export */
const DEFAULT_USER = {
  login: null,
  password: null,
};

const DEFAULT_PHONE = {
  id: null,
  phone: null,
};

const DEFAULT_EMAIL = {
  id: null,
  email: null,
};

const DEFAULT_ACCOUNT = {
  id: null,
  login: null,
  type: null,
};

export {
  DEFAULT_USER, DEFAULT_PHONE, DEFAULT_EMAIL, DEFAULT_ACCOUNT,
};

export const TYPES_EMAIL = 'TYPE_EMAIL';
export const TYPES_INVALID = 'TYPE_INVALID';
export const TYPES_LOGIN = 'TYPE_LOGIN';
export const TYPES_PHONE = 'TYPE_PHONE';
export const TYPES_SAP = 'TYPE_SAP';

export const TYPES = [TYPES_EMAIL, TYPES_INVALID, TYPES_LOGIN, TYPES_PHONE, TYPES_SAP];
export const TYPES_LIST = [
  {
    id: 1,
    title: 'E-mail',
    value: TYPES_EMAIL,
  },
  {
    id: 2,
    title: 'Логин',
    value: TYPES_LOGIN,
  },
  {
    id: 3,
    title: 'Телефон',
    value: TYPES_PHONE,
  },
  {
    id: 4,
    title: 'SAP',
    value: TYPES_SAP,
  },
];
